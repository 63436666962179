import {NgModule}             from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent}      from './core/dashboard/components/containers';
import {P404Component}        from './shared/components/error/404.component';
import {AuthGuard}            from './shared/guards/auth.guard';
import {PermissionGuard}      from './shared/guards/permission.guard';

export const routes: Routes = [
    {
        path:       '',
        pathMatch:  'full',
        redirectTo: 'app',
    },
    {
        path:         '',
        loadChildren: () => import('./core/auth/auth.module').then(m => m.AuthModule),
    },

    {
        path:       'signed-in-redirect',
        pathMatch:  'full',
        redirectTo: 'app',
    },
    {
        path:             'app',
        canActivateChild: [AuthGuard, PermissionGuard],
        component:        LayoutComponent,
        children:         [
            {
                path:         '',
                data:         {permission: 'view_dashboard'},
                loadChildren: () => import('./core/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path:         '',
                data:         {permission: 'view_organisations'},
                loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
            },
            {
                path:         '',
                loadChildren: () => import('./modules/callback/callback.module').then(m => m.CallbackModule),
            },
            {
                path:         '',
                loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule),
            },
            {
                path:         '',
                loadChildren: () => import('./modules/call-management/call-management.module').then(m => m.CallManagementModule),
            },
            {
                path:         '',
                data:         {permission: 'view_contacts'},
                loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
            },
            {
                path:         '',
                data:         {permission: 'view_roles'},
                loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),
            },
            {
                path:         '',
                data:         {permission: 'view_users'},
                loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
            },
            {
                path:         '',
                data:         {permission: 'view_opportunities'},
                loadChildren: () => import('./modules/opportunities/opportunities.module').then(m => m.OpportunitiesModule),
            },
            {
                path:         '',
                data:         {permission: 'view_products'},
                loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
            },
            {
                path:         '',
                data:         {permission: 'view_product_groups'},
                loadChildren: () => import('./modules/product-group/product-group.module').then(m => m.ProductGroupModule),
            },
            {
                path:         '',
                loadChildren: () => import('./modules/agenda/calendar.module').then(m => m.CalendarModule),
            },
            {
                path:         '',
                loadChildren: () => import('./modules/product-category/product-category.module').then(m => m.ProductCategoryModule),
            },
            {
                path:         '',
                data:         {permission: 'view_teams'},
                loadChildren: () => import('./modules/teams/teams.module').then(m => m.TeamsModule),
            },
            {
                path:         '',
                data:         {permission: 'view_pipelines'},
                loadChildren: () => import('./modules/pipelines/pipelines.module').then(m => m.PipelinesModule),
            },
            {
                path:         '',
                data:         {permission: 'view_quotes'},
                loadChildren: () => import('./modules/quotes/quotes.module').then(m => m.QuotesModule),
            },
            {
                path:         '',
                data:         {permission: 'view_pricelists'},
                loadChildren: () => import('./modules/price/price.module').then(m => m.PriceModule),
            },
            {
                path:         '',
                data:         {permission: 'view_purchase_orders'},
                loadChildren: () => import('./modules/purchase-orders/purchase-order.module').then(m => m.PurchaseOrderModule),
            },
            {
                path:         '',
                data:         {permission: 'view_sale_orders'},
                loadChildren: () => import('./modules/sale-orders/sale-order.module').then(m => m.SaleOrderModule),
            },
            {
                path:         '',
                data:         {permission: 'view_targets'},
                loadChildren: () => import('./modules/targets/targets.module').then(m => m.TargetsModule),
            },
            {
                path:         '',
                data:         {permission: 'view_discounts'},
                loadChildren: () => import('./modules/master/discount-code/discount-code.module').then(m => m.DiscountCodeModule),
            },
            {
                path:         '',
                data:         {permission: 'view_sites'},
                loadChildren: () => import('./modules/sites/sites.module').then(m => m.SitesModule),
            },
            {
                path:         '',
                data:         {permission: 'view_projects'},
                loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule),
            },
            {
                path:         '',
                loadChildren: () => import('./modules/master/quote-validity/quote-validity.module').then(m => m.QuoteValidityModule),
            },
            {
                path:         '',
                data:         {permission: 'add_vat_rate'},
                loadChildren: () => import('./modules/master/vat-rate/vat-rate.module').then(m => m.VatRateModule),
            },
            {
                path:         '',
                data:         {permission: 'view_tickets'},
                loadChildren: () => import('./modules/tickets/tickets.module').then(m => m.TicketsModule),
            },
            {
                path:         '',
                data:         {permission: 'view_assets'},
                loadChildren: () => import('./modules/assets/assets.module').then(m => m.AssetsModule),
            },
            {
                path:         '',
                // data:         {permission: 'view_org_mapping'},
                loadChildren: () => import('./modules/master/org-email-mapping/org-email-mapping.module').then(m => m.OrgEmailMappingModule),
            },
            {
                path:         '',
                loadChildren: () => import('./core/help/help.module').then(m => m.HelpModule),
            },
        ],
    },
    {path: '**', component: P404Component},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule
{
}
