import {ICellRendererAngularComp}          from 'ag-grid-angular';
import {Component, ViewChild}                         from '@angular/core';
import {ICellRendererParams}               from 'ag-grid-community';
import {GridAction}                        from '../../../models/grid-action.model';
import {SweetAlertIcon, SweetAlertOptions} from 'sweetalert2';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector:    'actions-cell-renderer',
    templateUrl: './actions-cell-renderer.component.html',
    styleUrls:   ['./actions-cell-renderer.component.css'],
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp
{
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    public params!: any;
    public swalOptions: SweetAlertOptions = {
        title: '',
        text:  'You can\'t undo this action.',
        icon:  'warning',
        
    };

    public swalMessageOptions: SweetAlertOptions = {
        title: '',
        text:  'It cannot be deleted.',
        icon:  'warning',
        showCancelButton: false,
        customClass: "Custom_Cancel"
    };
    public swalAssociateOrgOptions:SweetAlertOptions = {
        title: '',
        text:  'This will delete associated records also.',
        icon:  'warning',
    };
    

    public agInit(params: ICellRendererParams): void
    {
        this.params = params;        
        this.swalOptions.title = `Are you sure you want to delete this ${this.params.delete?.type ?? 'record'}?`;
        this.swalMessageOptions.title = `Only status of this ${this.params.deleteMessage?.type } can be changed`;
        this.swalAssociateOrgOptions.title = `Are you sure you want to delete this ${this.params.deleteAssoOrg?.type ?? 'record'}?`;

    }

    public refresh(params: ICellRendererParams): boolean
    {
        this.params = params;
        return true;
    }

    public onEdit()
    {        
        if (this.params.edit.onEdit instanceof Function) {
            this.params.edit.onEdit(this.params?.data);
        }
    }

    public productPrice()
    {        
        if (this.params.price.productPrice instanceof Function) {
            this.params.price.productPrice(this.params?.data);
        }
    }
    
    public onDelete()
    {       
        if (this.params.delete.onDelete instanceof Function) {
            this.params.delete.onDelete(this.params?.data);
        }
    }

    public onDeleteOrg()
    {              
        if (this.params.deleteAssoOrg.onDelete instanceof Function) {
            this.params.deleteAssoOrg.onDelete(this.params?.data);
        }
    }

    public onAction(action: GridAction)
    {
        action.onAction(this.params?.data);
    }

    public closeMenu(){
        this.trigger.closeMenu();
    }
}
