import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UsersService } from '../../../modules/users/services/users.service';
import { BaseService } from '../../../shared/services/base.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PermissionsService } from './permissions.service';
import { CommonListValuesService } from '../../../shared/services/common-list-values.service';
import { SettingsService } from './settings.service';
import { DataService } from '../../../shared/services/data.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    public authenticated: boolean = false;

    public constructor(
        public jwtHelper: JwtHelperService,
        private httpClient: HttpClient,
        private router: Router,
        private permissionsService: PermissionsService,
        private usersService: UsersService,
        private dataService: DataService,
        private commonListValuesService: CommonListValuesService,
        private settingsServices: SettingsService,
    ) {
        super();
    }

    public get accessToken(): string {
        return localStorage.getItem('accessToken') ?? null;
    }

    public set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    public login(credentials: { email: string, password: string }): Promise<any> {
        if (this.authenticated) {
            this.handleError('User is already logged in.');
        }

        return new Promise((r, rj) => {
            return this.httpClient.post(`${this.apiUrl}/login`, credentials).subscribe(response => {
                this.authenticateUser(response, true).subscribe((v) => {
                    r(response);
                });
            }, e => rj(e))
        });

    }

    public signInUsingToken(): Observable<any> {
        if (this.accessToken) {
            return this.httpClient.post(`${this.apiUrl}/refresh`, {
                token: this.accessToken,
            }).pipe(
                catchError(() => of(false)),
                switchMap((response: any) => {
                    this.authenticateUser(response);
                    return of(true);
                }),
            );
        }

        return of(false);
    }

    public logout(): Observable<any> {
        this.deauthenticateUser();
        return of(true);
    }

    public forgotPassword(email: string): Observable<any> {
        return this.httpClient
            .post<any>(`${this.apiUrl}/forgot-password`, email)
            .pipe(switchMap((response: any) => of(response)));
    }

    public resetPassword(token: string, password: string): Observable<any> {
        return this.httpClient
            .post<any>(`${this.apiUrl}/reset-password/${token}`, password)
            .pipe(switchMap((response: any) => of(response)));
    }

    public check(): Observable<boolean> {
        if (this.accessToken === 'undefined') {
            return of(false);
        }

        if (this.jwtHelper.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        if (this.authenticated) {
            return of(true);
        }

        if (!this.accessToken || this.accessToken === 'undefined') {
            return of(false);
        }

        return this.signInUsingToken();
    }

    private authenticateUser(response: any, updateLocalData: boolean = false) {
        this.accessToken = response.access_token;
        this.authenticated = true;
        this.usersService.user = response.user;
        this.permissionsService.roles = response.roles;
        this.permissionsService.permissions = response.permissions;
        this.settingsServices.authUser = response.user;

        if (updateLocalData) {
            return of(null);
            return this.updateLocalData();
        } else {
            return of(null);
        }
    }

    private updateLocalData(): Observable<any> {
        const subscriptionList = [
            this.commonListValuesService.listValuesMultiple([
                "priority_level", "activity_type", "frequencies",
                "organisation_type", "product_status", "calls_direction",
                "call_statuses", "sector", "organisation_source",
                "organisation_statuses", "organisation_payment_methods",
                "organisation_payment_terms_in_days", "organisation_rating_levels",
                "organisation_association_statuses", "organisation_association_types",
                "address_statuses", "address_types", "opportunity_types",
                "opportunity_source", "organisation_territory", "quote_lost_reason",
                "quote_status", "quote_type", "quote_payment_frequency",
                "purchase_order_statuses", "purchase_order_types",
                "sale_order_statuses", "sale_order_types", "contact_status",
                "project_statuses", "opportunity_statuses", "opportunity_stages",
                "opportunity_business_types", "meeting_statuses", "target_type", "likelihoods",
                "sale_order_dispatch_status"
            ]),
            this.commonListValuesService.listValues("wholesale_retail"),
            this.dataService.list("pipelines"),
            this.dataService.list("products/opt/groups"),
            this.dataService.list("activity/calls/outcomes"),
            this.dataService.list("activity/calls/objectives"),
            this.dataService.list("teams"),
            this.dataService.list("quotes"),
            this.dataService.list("organisations"),
            this.dataService.list("contacts"),
            this.dataService.list("opportunities/sources"),
            this.dataService.list("opportunities/closed-reasons"),
            this.dataService.list("activity/meetings/objectives"),
            this.dataService.list("activity/meetings/outcomes"),
            this.usersService.list(),
        ];

        return forkJoin(subscriptionList).pipe(tap((responseList) => {
            const [
                listValuesRes,
                wholesale_retail,                
                pipelines,
                productGroups, callOutcomes,
                callObjectives, teams, quotes,
                organisations, contacts, opportunitySources,
                opportunityClosedReasons, meetingObjectives, meetingOutcomes,users
            ] = responseList;
            this.settingsServices.setValue("priorities", listValuesRes.priority_level);
            this.settingsServices.setValue("activityTypes", listValuesRes.activity_type);
            this.settingsServices.setValue("frequencies", listValuesRes.frequencies);
            this.settingsServices.setValue("organisationTypes", listValuesRes.organisation_type);
            this.settingsServices.setValue("productStatuses", listValuesRes.product_status);
            this.settingsServices.setValue("callDirections", listValuesRes.calls_direction);
            this.settingsServices.setValue("callStatuses", listValuesRes.call_statuses);
            this.settingsServices.setValue("sectors", listValuesRes.sector);
            this.settingsServices.setValue("organisationSources", listValuesRes.organisation_source);
            this.settingsServices.setValue("organisationStatuses", listValuesRes.organisation_statuses);
            this.settingsServices.setValue("organisationPaymentMethods", listValuesRes.organisation_payment_methods);
            this.settingsServices.setValue("organisationPaymentTerms", listValuesRes.organisation_payment_terms_in_days);
            this.settingsServices.setValue("organisationRatingLevels", listValuesRes.organisation_rating_levels);
            this.settingsServices.setValue("organisationAssociationStatuses", listValuesRes.organisation_association_statuses);
            this.settingsServices.setValue("organisationAssociationTypes", listValuesRes.organisation_association_types);
            this.settingsServices.setValue("addressStatuses", listValuesRes.address_statuses);
            this.settingsServices.setValue("addressTypes", listValuesRes.address_types);
            this.settingsServices.setValue("opportunityTypes", listValuesRes.opportunity_types);
            this.settingsServices.setValue("opportunitySource", listValuesRes.opportunity_source);
            this.settingsServices.setValue("organisationTerritories", listValuesRes.organisation_territory);
            this.settingsServices.setValue("quoteLostReasons", listValuesRes.quote_lost_reason);
            this.settingsServices.setValue("quoteStatuses", listValuesRes.quote_status);
            this.settingsServices.setValue("quoteTypes", listValuesRes.quote_type);
            this.settingsServices.setValue("quotePaymentFrequencies", listValuesRes.quote_payment_frequency);
            this.settingsServices.setValue("purchaseOrderStatuses", listValuesRes.purchase_order_statuses);
            this.settingsServices.setValue("purchaseOrderTypes", listValuesRes.purchase_order_types);
            this.settingsServices.setValue("saleOrderStatuses", listValuesRes.sale_order_statuses);
            this.settingsServices.setValue("saleOrderTypes", listValuesRes.sale_order_types);
            this.settingsServices.setValue("sale_order_dispatch_status", listValuesRes.sale_order_dispatch_status);
            this.settingsServices.setValue("contactStatuses", listValuesRes.contact_status);
            this.settingsServices.setValue("projectStatuses", listValuesRes.project_statuses);
            this.settingsServices.setValue("opportunityStatuses", listValuesRes.opportunity_statuses);
            this.settingsServices.setValue("opportunityStages", listValuesRes.opportunity_stages);
            this.settingsServices.setValue("opportunityBusinessTypes", listValuesRes.opportunity_business_types);
            this.settingsServices.setValue("meetingStatuses", listValuesRes.meeting_statuses);
            this.settingsServices.setValue('targetTypes', listValuesRes.target_type);
            this.settingsServices.setValue("likelihoods", listValuesRes.likelihoods);
            this.settingsServices.setValue("wholesale_retails", wholesale_retail.data);           
            this.settingsServices.setValue("pipelines", pipelines.data);
            this.settingsServices.setValue("productGroups", productGroups.data);
            this.settingsServices.setValue("callOutcomes", callOutcomes.data);
            this.settingsServices.setValue("callObjectives", callObjectives.data);
            this.settingsServices.setValue("teams", teams.data);
            this.settingsServices.setValue("quotes", quotes.data);
            this.settingsServices.setValue("organisations", organisations.data); //TODO converted to API lookups
            this.settingsServices.setValue("contacts", contacts.data); //TODO converted to API lookups
            this.settingsServices.setValue("opportunitySources", opportunitySources.data);
            this.settingsServices.setValue("opportunityClosedReasons", opportunityClosedReasons.data);
            this.settingsServices.setValue("meetingObjectives", meetingObjectives.data);
            this.settingsServices.setValue("meetingOutcomes", meetingOutcomes.data);
            this.settingsServices.setValue("sale_order_dispatch_status", listValuesRes.sale_order_dispatch_status);
            this.settingsServices.setValue("users", users.data);
        }));


    }

    // private updateLocalData() {
    //     forkJoin([
    //         this.commonListValuesService.listValues("priority_level"),
    //         this.commonListValuesService.listValues("activity_type"),
    //         this.commonListValuesService.listValues("frequencies"),
    //         this.commonListValuesService.listValues("organisation_type"),
    //         this.usersService.list(),
    //         this.dataService.list("pipelines"),
    //         this.dataService.list("products/opt/groups"),
    //         this.commonListValuesService.listValues("product_status"),
    //         this.dataService.list("activity/calls/outcomes"),
    //         this.dataService.list("activity/calls/objectives"),
    //         this.commonListValuesService.listValues("calls_direction"),
    //         this.commonListValuesService.listValues("call_statuses"),
    //         this.commonListValuesService.listValues("sector"),
    //         this.commonListValuesService.listValues("organisation_source"),
    //         this.commonListValuesService.listValues("organisation_statuses"),
    //         this.commonListValuesService.listValues("organisation_payment_methods"),
    //         this.commonListValuesService.listValues("organisation_payment_terms_in_days"),
    //         this.commonListValuesService.listValues("organisation_rating_levels"),
    //         this.commonListValuesService.listValues("organisation_association_statuses"),
    //         this.commonListValuesService.listValues("organisation_association_types"),
    //         this.commonListValuesService.listValues("address_statuses"),
    //         this.commonListValuesService.listValues("address_types"),
    //         this.commonListValuesService.listValues("opportunity_types"),
    //         this.commonListValuesService.listValues("opportunity_source"),
    //         this.commonListValuesService.listValues("organisation_territory"),
    //         this.commonListValuesService.listValues("quote_lost_reason"),
    //         this.commonListValuesService.listValues("quote_status"),
    //         this.commonListValuesService.listValues("quote_type"),
    //         this.commonListValuesService.listValues("quote_payment_frequency"),
    //         this.commonListValuesService.listValues("purchase_order_statuses"),
    //         this.commonListValuesService.listValues("purchase_order_types"),
    //         this.commonListValuesService.listValues("sale_order_statuses"),
    //         this.commonListValuesService.listValues("sale_order_types"),
    //         this.commonListValuesService.listValues("contact_status"),
    //         this.commonListValuesService.listValues("project_statuses"),
    //         this.dataService.list("teams"),
    //         this.dataService.list("quotes"),
    //         this.dataService.list("organisations"),
    //         this.dataService.list("contacts"),
    //         this.dataService.list("opportunities/sources"),
    //         this.dataService.list("opportunities/closed-reasons"),
    //         this.commonListValuesService.listValues("opportunity_statuses"),
    //         this.commonListValuesService.listValues("opportunity_stages"),
    //         this.commonListValuesService.listValues("opportunity_business_types"),
    //         this.commonListValuesService.listValues("likelihoods", "direction"),
    //         this.dataService.list("activity/meetings/objectives"),
    //         this.dataService.list("activity/meetings/outcomes"),
    //         this.commonListValuesService.listValues("meeting_statuses"),
    //         this.commonListValuesService.listValues('target_type'),
    //     ]).subscribe(
    //         ([
    //             priorities,
    //             activityTypes,
    //             frequencies,
    //             organisationTypes,
    //             users,
    //             pipelines,
    //             productGroups,
    //             productStatuses,
    //             callOutcomes,
    //             callObjectives,
    //             callDirections,
    //             callStatuses,
    //             sectors,
    //             organisationSources,
    //             organisationStatuses,
    //             organisationPaymentMethods,
    //             organisationPaymentTerms,
    //             organisationRatingLevels,
    //             organisationAssociationStatuses,
    //             organisationAssociationTypes,
    //             addressStatuses,
    //             addressTypes,
    //             opportunityTypes,
    //             opportunitySource,
    //             organisationTerritories,
    //             quoteLostReasons,
    //             quoteStatuses,
    //             quoteTypes,
    //             quotePaymentFrequencies,
    //             purchaseOrderStatuses,
    //             purchaseOrderTypes,
    //             saleOrderStatuses,
    //             saleOrderTypes,
    //             contactStatuses,
    //             projectStatuses,
    //             teams,
    //             quotes,
    //             organisations,
    //             contacts,
    //             opportunitySources,
    //             opportunityClosedReasons,
    //             opportunityStatuses,
    //             opportunityStages,
    //             opportunityBusinessTypes,
    //             likelihoods,
    //             meetingObjectives,
    //             meetingOutcomes,
    //             meetingStatuses,
    //             targetTypes
    //         ]) => {
    //             this.settingsServices.setValue("priorities", priorities.data);
    //             this.settingsServices.setValue("activityTypes", activityTypes.data);
    //             this.settingsServices.setValue("frequencies", frequencies.data);
    //             this.settingsServices.setValue("organisationTypes", organisationTypes.data);
    //             this.settingsServices.setValue("users", users.data);
    //             this.settingsServices.setValue("pipelines", pipelines.data);
    //             this.settingsServices.setValue("productGroups", productGroups.data);
    //             this.settingsServices.setValue("productStatuses", productStatuses.data);
    //             this.settingsServices.setValue("callOutcomes", callOutcomes.data);
    //             this.settingsServices.setValue("callObjectives", callObjectives.data);
    //             this.settingsServices.setValue("callDirections", callDirections.data);
    //             this.settingsServices.setValue("callStatuses", callStatuses.data);
    //             this.settingsServices.setValue("sectors", sectors.data);
    //             this.settingsServices.setValue("organisationSources", organisationSources.data);
    //             this.settingsServices.setValue("organisationStatuses", organisationStatuses.data);
    //             this.settingsServices.setValue("organisationPaymentMethods", organisationPaymentMethods.data);
    //             this.settingsServices.setValue("organisationPaymentTerms", organisationPaymentTerms.data);
    //             this.settingsServices.setValue("organisationRatingLevels", organisationRatingLevels.data);
    //             this.settingsServices.setValue("organisationAssociationStatuses", organisationAssociationStatuses.data);
    //             this.settingsServices.setValue("organisationAssociationTypes", organisationAssociationTypes.data);
    //             this.settingsServices.setValue("addressStatuses", addressStatuses.data);
    //             this.settingsServices.setValue("addressTypes", addressTypes.data);
    //             this.settingsServices.setValue("opportunityTypes", opportunityTypes.data);
    //             this.settingsServices.setValue("opportunitySource", opportunitySource.data);
    //             this.settingsServices.setValue("organisationTerritories", organisationTerritories.data);
    //             this.settingsServices.setValue("quoteLostReasons", quoteLostReasons.data);
    //             this.settingsServices.setValue("quoteStatuses", quoteStatuses.data);
    //             this.settingsServices.setValue("quoteTypes", quoteTypes.data);
    //             this.settingsServices.setValue("quotePaymentFrequencies", quotePaymentFrequencies.data);
    //             this.settingsServices.setValue("purchaseOrderStatuses", purchaseOrderStatuses.data);
    //             this.settingsServices.setValue("purchaseOrderTypes", purchaseOrderTypes.data);
    //             this.settingsServices.setValue("saleOrderStatuses", purchaseOrderStatuses.data);
    //             this.settingsServices.setValue("saleOrderTypes", purchaseOrderTypes.data);
    //             this.settingsServices.setValue("contactStatuses", contactStatuses.data);
    //             this.settingsServices.setValue("projectStatuses", projectStatuses.data);
    //             this.settingsServices.setValue("teams", teams.data);
    //             this.settingsServices.setValue("quotes", quotes.data);
    //             this.settingsServices.setValue("organisations", organisations.data); //TODO converted to API lookups
    //             this.settingsServices.setValue("contacts", contacts.data); //TODO converted to API lookups
    //             this.settingsServices.setValue("opportunitySources", opportunitySources.data);
    //             this.settingsServices.setValue("opportunityClosedReasons", opportunityClosedReasons.data);
    //             this.settingsServices.setValue("opportunityStatuses", opportunityStatuses.data);
    //             this.settingsServices.setValue("opportunityStages", opportunityStages.data);
    //             this.settingsServices.setValue("opportunityBusinessTypes", opportunityBusinessTypes.data);
    //             this.settingsServices.setValue("likelihoods", likelihoods.data);
    //             this.settingsServices.setValue("meetingObjectives", meetingObjectives.data);
    //             this.settingsServices.setValue("meetingOutcomes", meetingOutcomes.data);
    //             this.settingsServices.setValue("meetingStatuses", meetingStatuses.data);
    //             this.settingsServices.setValue('targetTypes', targetTypes.data);
    //         }
    //     );
    // }

    private deauthenticateUser() {
        this.authenticated = false;
        // this.usersService.user = null;

        localStorage.removeItem('accessToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('permissions');
    }
}
