import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform
{
    public constructor(private sanitizer: DomSanitizer)
    {
    }

    public transform(url: string): SafeResourceUrl
    {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform
{
    public constructor(private sanitizer: DomSanitizer)
    {
    }
    transform(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
      }
}